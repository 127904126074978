import { Button } from "@/components/ui/button"
import { BiHomeCircle } from "react-icons/bi"
import { RxReload } from "react-icons/rx"
import { isRouteErrorResponse, useNavigate, useRouteError } from "react-router-dom"

function RootBoundary() {
  const error = useRouteError()
  const navigate = useNavigate()

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <div>This page doesn't exist!</div>
    }

    if (error.status === 401) {
      return <div>You aren't authorized to see this</div>
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>
    }

    if (error.status === 418) {
      return <div>🫖</div>
    }
  }

  const handleRestartApp = () => {
    localStorage.clear()
    navigate("/")
    window.location.href = "/"
  }

  return (
    <div className="pt-20 text-center">
      <p className="text-xl font-bold">Something went wrong</p>
      <div className="mt-4 space-x-4">
        <Button onClick={() => navigate("/")}>
          <BiHomeCircle className="mr-1" /> Back to home
        </Button>
        <Button onClick={handleRestartApp}>
          <RxReload className="mr-1" />
          Restart the app
        </Button>
      </div>
    </div>
  )
}

export { RootBoundary }
