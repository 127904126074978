import { cn } from "@/utils/utils"
import { NavLink } from "react-router-dom"

type MenuItemProps = {
  link: string
  icon: React.ReactNode
  name: string
}

const MenuItem: React.FC<MenuItemProps> = ({ link, icon, name }) => {
  return (
    <li>
      <NavLink
        to={link}
        className={({ isActive }) => {
          return cn(
            "relative flex items-center p-5 text-xl rounded-s-full",
            isActive && "bg-background cursor-auto pointer-events-none font-bold",
            "before:absolute before:right-0 before:w-10",
            "after:absolute after:right-0 after:w-10",
          )
        }}>
        <i className="text-2xl text-on-surface-subdued">{icon}</i>
        <span className="ml-5 text-on-surface-subdued">{name}</span>
      </NavLink>
    </li>
  )
}

export { MenuItem }
