import { Link, useMatch } from "react-router-dom"
import LogoImage from "./../../images/logo.png"
import { useTranslation } from "react-i18next"
import { useRole } from "@/store/authStore"
import {
  AiOutlineDashboard,
  AiOutlineInfoCircle,
  AiOutlineSetting,
  AiOutlineUser,
} from "react-icons/ai"
import { BsBookmarkHeartFill, BsListColumns } from "react-icons/bs"
import { MenuItem } from "./menu-item"
import { MdOutlineExplore } from "react-icons/md"
import { RxHamburgerMenu } from "react-icons/rx";
import { cn } from "@/utils/utils"

const ROLES = {
  Admin: "Admin",
  Manager: "Manager",
  Teacher: "Teacher",
  User: "User",
}

const Sidebar = () => {
  const { t } = useTranslation()
  const role = useRole()
  const isDashboardPage = useMatch("/")

  return (
    <>
    <div className="md:hidden absolute left-4 top-4 z-50">
      <RxHamburgerMenu size={25} />
    </div>
    <div className={cn(
        "hidden md:flex flex-col bg-background-subdued"
      )}
    >
      <h1 className="w-[214px] h-[63px] mx-auto my-10">
        <Link to="/">
          <img src={LogoImage} />
        </Link>
      </h1>
      <ul className="pl-4 mt-20">
        <MenuItem
          link="/"
          icon={<AiOutlineDashboard />}
          name={t("Dashboard")}
        />
        {(role === ROLES.User || role === ROLES.Admin) && (
          <MenuItem
            link="/bookmark"
            icon={<BsBookmarkHeartFill />}
            name={t("Bookmark")}
          />
        )}
        {role === ROLES.Admin && (
          <MenuItem
            link="/explore"
            icon={<MdOutlineExplore />}
            name={t("Explore")}
          />
        )}
        {role === ROLES.Admin && (
          <MenuItem
            link="/vocab-list"
            icon={<BsListColumns />}
            name={t("Vocab List")}
          />
        )}
        {(role === ROLES.User || role === ROLES.Admin) && (
          <MenuItem
            link="/setting"
            icon={<AiOutlineSetting />}
            name={t("Setting")}
          />
        )}
        {role === ROLES.Admin && (
          <MenuItem link="/user" icon={<AiOutlineUser />} name={t("User")} />
        )}
        <MenuItem
          link="/about"
          icon={<AiOutlineInfoCircle />}
          name={t("Feedback")}
        />
      </ul>
      {isDashboardPage && (
        <div className="mt-auto shadow-xl mb-10 bg-background-subdued rounded-xl text-center mx-5 p-5">
          <p className="text-on-surface">
            “Bấm vào vị trí bất
            <br />
            kì trên thẻ từ để lật nghĩa”
          </p>
        </div>
      )}
    </div>
    </>
  )
}

export { Sidebar }
