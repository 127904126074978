import { useState, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Flashcard } from "../../component/Flashcard"
import usePrevious from "../../hooks/usePrevious"
import { useUserFlashcardQuery$data } from "./hooks/__generated__/useUserFlashcardQuery.graphql"
import { useRandomFlashcardQuery$data } from "./hooks/__generated__/useRandomFlashcardQuery.graphql"
import { Button } from "@/components/ui/button"
import { useUserBookmarkQuery$data } from "@/pages/Bookmark/services/__generated__/useUserBookmarkQuery.graphql"
import { useUserBookmarkQuery } from "../Bookmark/services/useUserBookmarkQuery"
import { getAccessToken } from "../../store/authStore"
import { useRandomFlashcardQuery } from "./hooks/useRandomFlashcardQuery"
import { useUserFlashcardQuery } from "./hooks/useUserFlashcardQuery"
import { useUserSettingsStore } from "@/store/userSettingsStore"
import { QueryOptions } from "@/types/QueryOptions"

type BookmarkNode = NonNullable<useUserBookmarkQuery$data["getUserBookmarks"]>["edges"][number]
type RandomFlashcardNode = NonNullable<useUserFlashcardQuery$data["getUserRandomFlashcards"]>["edges"][number]

export type FlashcardType = RandomFlashcardNode | BookmarkNode

export type Flashcards = NonNullable<useUserFlashcardQuery$data["getUserRandomFlashcards"]>["edges"] &
  NonNullable<useRandomFlashcardQuery$data["randomFlashcard"]>["edges"]

const FlashcardArea = () => {
  const { t } = useTranslation()
  const settingsVersion = useUserSettingsStore((state) => state.settingsVersion)
  const resetSettingsVersion = useUserSettingsStore((state) => state.resetSettingsVersion)

  const isAuthenticated = Boolean(getAccessToken())

  const [hasInitiallyFetched, setHasInitiallyFetched] = useState(false)
  const queryOptions = useMemo(() => {
    if (!hasInitiallyFetched && settingsVersion > 0) {
      return { fetchKey: settingsVersion, fetchPolicy: "network-only" }
    }
    return {}
  }, [hasInitiallyFetched, settingsVersion])

  const userFlashcards = useUserFlashcardQuery({ skip: !isAuthenticated, options: queryOptions as QueryOptions })

  useEffect(() => {
    if (settingsVersion > 0 && !hasInitiallyFetched) {
      setHasInitiallyFetched(true)
      resetSettingsVersion()
    }
  }, [settingsVersion, hasInitiallyFetched, resetSettingsVersion])

  const randomFlashcards = useRandomFlashcardQuery({ skip: isAuthenticated })
  const bookmarks = useUserBookmarkQuery({
    skip: !isAuthenticated,
  })

  const bookmarkedIds =
    bookmarks?.edges.reduce<string[]>((acc, edge) => {
      const flashcardId = edge.node.flashcard?.id
      if (flashcardId) return [...acc, flashcardId]
      return [...acc]
    }, []) ?? []

  const bookmarkedFlashcards: BookmarkNode[] = bookmarks?.edges ? [...bookmarks.edges] : []

  const flashcards = [
    ...((isAuthenticated
      ? userFlashcards["getUserRandomFlashcards"]?.edges
      : randomFlashcards["randomFlashcard"]?.edges) ?? []),
    ...bookmarkedFlashcards,
  ]

  const [flashcard, setFlashcard] = useState<FlashcardType | null>(flashcards ? flashcards[0] : null)
  const prevFlashcard = usePrevious(flashcard)

  const handleNewWordRandom = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur()
    const randomIndex = Math.floor(Math.random() * flashcards.length)
    const selectedFlashcard = flashcards[randomIndex]
    setFlashcard(selectedFlashcard)
  }

  const handlePreviousFlashcardSwitch = () => {
    if (prevFlashcard) setFlashcard(prevFlashcard)
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        handleNewWordRandom(event as unknown as React.MouseEvent<HTMLButtonElement>)
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="m-10 flex-1 overflow-hidden">
      <div>
        {flashcard && (
          <Flashcard
            key={flashcard.node.word}
            {...flashcard}
            isBookmarked={bookmarkedIds.includes(flashcard.node.id)}
          />
        )}
        {flashcards && flashcard && (
          <div className="flex justify-center space-x-5">
            <Button variant="secondary" disabled={!prevFlashcard} onClick={handlePreviousFlashcardSwitch}>
              {t("Previous word")}
            </Button>
            <Button variant="secondary" onClick={handleNewWordRandom}>
              {t("New word")}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export { FlashcardArea }
