import { BsSearch } from "react-icons/bs"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { useTranslation } from "react-i18next"
import { PreloadedQuery, usePreloadedQuery } from "react-relay"
import { SearchQuery } from "@/modules/search/services/search-query"
import useSearchLoader from "@/modules/search/services/useSearchLoader"
import { Suspense, useCallback, useEffect, useState } from "react"
import { debounce, toString } from "lodash-es"
import { searchQuery } from "@/modules/search/services/__generated__/searchQuery.graphql"
import { Textarea } from "@/components/ui/textarea"

const SearchDialog = () => {
  const { t } = useTranslation()
  const [text, setText] = useState("")
  const [targetLang, setTargetLang] = useState("vi")
  const [sourceLang, setSourceLang] = useState("auto")

  const { queryReference, loadQuery, disposeQuery } = useSearchLoader()

  const debouncedLoadQuery = useCallback(
    debounce((variables) => {
      loadQuery(variables)
    }, 800), // 500ms delay
    [loadQuery],
  )
  useEffect(() => {
    if (text.trim() !== "") {
      debouncedLoadQuery({ text, targetLang, sourceLang })
    } else {
      disposeQuery()
    }

    // Cleanup on unmount
    return () => {
      debouncedLoadQuery.cancel()
    }
  }, [text, targetLang, sourceLang, debouncedLoadQuery, disposeQuery])
  return (
    <Dialog>
      <DialogTrigger asChild>
        <p
          className="transition-background bg-searchBg text-searchText relative h-12 w-72 cursor-text truncate rounded-full border-none bg-background-subdued pl-5 pr-16 text-base ease-in focus:outline-none focus-visible:outline-none dark:bg-background md:py-3"
        >
          {t("Enter search keyword")}...
          <BsSearch className="absolute right-5 top-1/2 -translate-y-1/2 text-on-surface-subdued" />
        </p>
      </DialogTrigger>
      <DialogContent className="flex h-[668px] w-[960px] max-w-none flex-col 2xl:h-[768px]">
        <DialogHeader>
          <DialogTitle>{t("search.Search")}</DialogTitle>
        </DialogHeader>
        <div className="mt-0 flex-1 overflow-auto px-1 py-1">
          <div className="mb-10 grid grid-cols-2 gap-8">
            <Input
              value={sourceLang}
              onChange={(e) => setSourceLang(e.target.value)}
              disabled
              placeholder="Source Language, e.g., auto"
            />
            <Input
              value={targetLang}
              onChange={(e) => setTargetLang(e.target.value)}
              placeholder="Target Language. e.g., vi"
            />
          </div>
          <div className="relative my-4 grid grid-cols-2 gap-6">
            <Textarea
              className="text-lg"
              autoFocus
              placeholder={t("search.Input to search")}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            {queryReference && (
              <Suspense fallback={<div>Loading...</div>}>
                <SearchResult queryReference={queryReference} />
              </Suspense>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const SearchResult = ({ queryReference }: { queryReference: PreloadedQuery<searchQuery> }) => {
  const { search } = usePreloadedQuery(SearchQuery, queryReference)

  return (
    <>
      {search.sentences && search.sentences[1] && (
        <p className="absolute left-3 top-0 text-xs italic">{search.sentences[1].src_translit}</p>
      )}
      <Textarea
        className="text-lg"
        value={search.sentences && search.sentences[0] ? toString(search.sentences[0].trans) : ""}
        readOnly
      />
      {search.definitions && (
        <Card>
          <CardHeader className="py-3">Definitions</CardHeader>
          <CardContent>
            <div>
              {search.definitions?.map((def, idx) => {
                return (
                  <div key={idx} className="mb-4">
                    <p className="font-bold">{def.pos}</p>
                    <ul className="ml-2 list-decimal">
                      {def.entry.map((e, eIdx) => {
                        return <li key={eIdx} dangerouslySetInnerHTML={{ __html: e.gloss }}></li>
                      })}
                    </ul>
                  </div>
                )
              })}
            </div>
          </CardContent>
        </Card>
      )}
      {search.examples && (
        <Card>
          <CardHeader className="py-3">Examples</CardHeader>
          <CardContent>
            <ol className="my-2 ml-7 list-outside list-decimal">
              {search.examples?.example.map((example, idx) => {
                return <li key={idx} dangerouslySetInnerHTML={{ __html: example.text }}></li>
              })}
            </ol>
          </CardContent>
        </Card>
      )}
    </>
  )
}

export { SearchDialog }
