import { Button } from "@/components/ui/button"
import { useTopicQuery } from "@/pages/Setting/services/useTopicQuery"
import { useMeQuery } from "@/services/useMeQuery"
import { useAccessToken } from "@/store/authStore"
import { useTranslation } from "react-i18next"
import { FiEdit } from "react-icons/fi"
import { Link } from "react-router-dom"

const InformationSidebar = () => {
  const isAuthenticated = useAccessToken()

  return (
    <div className="hidden w-72 flex-shrink-0 border-l border-border px-5 py-10 xl:block">
      {isAuthenticated && <CurrentTopicSection />}
    </div>
  )
}

const CurrentTopicSection = () => {
  const { t } = useTranslation()
  const me = useMeQuery()
  const topics = useTopicQuery()

  const chosenTopics = me?.chosenTopics ?? []
  const filteredTopics = topics.filter((topic) => chosenTopics.includes(topic.id))

  return (
    <>
      <p className="text-lg font-bold flex items-center">{t("Current topic")}:
        <Button
          title="Thay đổi chủ đề"
          asChild
          className="w-7.5 h-7.5 ml-3.75 bg-iconBg text-icon hover:bg-iconBgHover hover:border-iconBgLineHover cursor-pointer rounded-full border-transparent p-2 text-lg transition-all">
          <Link to="/setting">
            <FiEdit />
          </Link>
        </Button>
      </p>
      <div className="mt-2.5">
        <ul className="list-disc overflow-hidden">
          {filteredTopics?.map((topic) => {
            return (
              <li key={topic.id} className="truncate list-inside">
                {topic.name}
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export { InformationSidebar }
